<template src="./index.html">

</template>

<script>
import {
  BBreadcrumb, BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow, BLink, BTooltip, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";
import '@core/scss/vue/libs/vue-good-table.scss'
import { getUser } from '@/auth/utils'
import vSelect from 'vue-select'
import studentsSubjectCourseSemester from "@/views/report/students_subject_coursesemester/index";

export default {
  name: 'index',
  components: {
    BBreadcrumb,
    BButton,
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    BLink,
    BTooltip,
    BFormGroup,
    VueGoodTable,
    vSelect,
    studentsSubjectCourseSemester
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      table: {
        fields: [
          {
            label: '#',
            field: 'rowNum',
            sortable: false,
            width: '5%',
            tdClass: 'text-center',
            thClass: 'text-center'
          },
          {
            label: 'Phòng ban',
            field: 'departmentName',
            filterOptions: {
              enabled: true,
              placeholder: 'Tên phòng ban',
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Mã môn học',
            field: 'code',
            filterOptions: {
              enabled: true,
              placeholder: 'Mã môn',
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Môn học',
            field: 'name',
            filterOptions: {
              enabled: true,
              placeholder: 'Tên môn',
              trigger: 'enter',
            },
            sortable: false,
          },
          {
            label: 'Số sinh viên đăng ký',
            field: 'countStudent',
            sortable: false,
            tdClass: 'text-center'
          },
        ],
        rows: []
      },
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: '',
            type: '',
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        organizationId: getUser().orgId,
        trainingSystemId: '',
        courseId: '',
        courseSemesterId: '',
        subjectId: '',
      },
      dataSend: {},
      titleModal: '',
    }
  },

  async created() {
    //init data FROM API
    this.filter.organizationId = this.user.orgId
    await Promise.all([
      this.getTrainingSystems({ organizationId: this.filter.organizationId }),
    ])
    if (this.trainingSystems.length > 0){
      this.filter.trainingSystemId = this.trainingSystems[0].value
      await this.onCoursesChange(this.filter.trainingSystemId)
    }
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      total: 'studentsRegisteredBySubject/total',
      dataRows: 'studentsRegisteredBySubject/dataRows',
      courses: 'dropdown/courses',
      trainingSystems: 'dropdown/trainingSystems',
      courseSemesters: 'dropdown/courseSemesters',
      subjects: 'dropdown/subjects',
    }),
  },
  watch: {
    // listening change data init

  },
  methods: {
    // handle even
    ...mapActions({
      getDataRows: 'studentsRegisteredBySubject/getDataRows',
      getTrainingSystems: 'dropdown/getTrainingSystems',
      getCourses: 'dropdown/getCourses',
      getCourseSemesters: 'dropdown/getCourseSemesters',
      getSubjects: 'dropdown/getSubjects',
    }),

    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      await this.getMainDataFromStore()
    },
    openModal() {
      this.dataSendForm = {}
      this.$bvModal.show('modal-schedule')
    },
    // load items is what brings back the rows from server

    async onTrainingSystemsChange(event) {
      this.filter.trainingSystemId = event
      this.filter.courseId = null
      this.filter.courseSemesterId = null
      await this.getCourses(this.filter)
      if (this.courses.length > 0) {
        this.filter.courseId = this.courses[0].value
        await this.onCoursesChange(this.filter.courseId)
      }
    },

    async onCoursesChange(event) {
      this.filter.courseId = event
      this.filter.courseSemesterId = null
      await this.getCourseSemesters({ organizationId: getUser().orgId, courseId: this.filter.courseId, status: null })
      if (this.courseSemesters.length > 0) {
        this.filter.courseSemesterId = this.courseSemesters[0].value
      }
    },


    async onSubjectChange(event) {
      this.filter.creditClassId = null
      await this.getCreditClasses(this.filter)
    },

    async exportExcel(){

    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        const params = {
          organizationId: this.filter.organizationId,
          courseSemesterId: this.filter.courseSemesterId,
          currentPage: this.filter.currentPage,
          itemsPerPage: this.filter.itemsPerPage,
          name: this.filter.name,
          code: this.filter.code
        }
        await this.getDataRows(params)
        this.table.rows = this.dataRows
      } catch (e) {
        this.showToast(`[${e.code}] ${e.message}`, 'errors', '')
      } finally {
        this.isLoading = false
      }
    },

    async showDetail(data){
      this.titleModal = 'Danh sách sinh viên đăng ký môn học [' + data.name + ']'
      this.dataSend.subjectId = data.id
      this.dataSend.courseSemesterId = this.filter.courseSemesterId
      this.$bvModal.show('modal-detail')
    },

    showToast(title, icon ,variant) {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
  },
}
</script>

<style scoped>

</style>
